import {Component, HostBinding, Input} from '@angular/core';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {FaVariant} from '../fa/fa.component';

@Component({
    selector: 'app-action-icon',
    template: `
        <fa [i]="icon" [variant]="variant"></fa>
    `,
})
export class ActionIconComponent extends NgbTooltip {
    @Input() icon: string;
    @Input('variant') defVariant: FaVariant = 'light';
    @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
    @Input() colorClass: string;
    @Input('class') classList = '';
    @Input() isVisible = true;

    @HostBinding('class') get classes() {
        return `action-icon action-icon-${this.colorClass} ${this.size} ${this.classList} ${this.isVisible ? '' : ' d-none'}`;
    }

    @HostBinding('class.active')
    @Input() active = false;

    container = 'body';
    @Input('tooltip') set tooltip(tooltip) {
        this.ngbTooltip = tooltip;
    }

    get variant(): FaVariant {
        if (['ellipsis-h', 'ellipsis-v'].includes(this.icon)) return 'regular';
        return this.active ? 'solid' : this.defVariant;
    }
}
